/* eslint-disable no-unused-expressions */
/* eslint-disable prefer-destructuring */
export const abroadProvinceKey = 'EE'

export const sortDataModelJson = json => {
  return json.sort((a, b) => {
    if (b.value === '' || a.value === '') {
      return 0
    }
    if (a.label.toLowerCase() > b.label.toLowerCase()) {
      return 1
    }
    if (b.label.toLowerCase() > a.label.toLowerCase()) {
      return -1
    }
    return 0
  })
}

export const addSelectDefault = (selectList, defaultText) => {
  return [{ value: '', label: defaultText }, ...selectList]
}

export const getPlaceSelectList = (t, dataModels, provinceKey) => {
  let selectList = []
  if (provinceKey) {
    selectList =
      provinceKey === abroadProvinceKey
        ? dataModels.countryList
        : addSelectDefault(
            dataModels.cityList[provinceKey],
            t('form:selectDefault')
          )
  }
  return sortDataModelJson(selectList)
}

export const isSelectDisabled = (property, values) => {
  return (
    !Object.prototype.hasOwnProperty.call(values, property) ||
    !values[property].length > 0
  )
}

export const getLabelForValue = (dataModels, valueListName, value) => {
  const matchingValues = dataModels[valueListName].filter(item => {
    return item.value === value
  })
  if (matchingValues.length) {
    return matchingValues[0].label
  }
  return value
}

export const scrollToElement = (selector, align = 'start') => {
  const element = document.querySelector(selector)
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: align,
      inline: 'center',
    })
  }
}

export const getOpenSection = () => {
  const header = document.querySelector('.c-form-section__header.is-active')
  const id =
    header && header.parentNode && header.parentNode.id
      ? header.parentNode.id
      : ''
  return id
}

export const trackOpenSection = () => {}
