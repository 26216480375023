import { getData } from '../../../services/data'

const getInitialValues = (
  initualRadioButtons,
  storefrontData,
  acceptPrivacyPolicy
) => {
  return {
    acceptPrivacyPolicy:
      getData(
        'data.custom.dataProtection.acceptPrivacyPolicy',
        storefrontData,
        initualRadioButtons,
        acceptPrivacyPolicy
      ) ?? false,
    transferFinancialServicesSpain:
      storefrontData?.custom?.dataProtection?.promotion
        ?.transferFinancialServicesSpain ?? false,
    transferVWServicesGroup:
      storefrontData?.custom?.dataProtection?.promotion
        ?.transferVWServicesGroup ?? false,
    transferVWServicesDealer:
      storefrontData?.custom?.dataProtection?.promotion
        ?.transferVWServicesDealer ?? false,
  }
}

export default getInitialValues

export const getPrivacyPolicy = () => {
  return `${process.env.PUBLIC_URL}/assets/${process.env
    .REACT_APP_WEBSITE_BASE_BRAND ??
    'cupra'}/documents/Politica_de_proteccion_de_datos_OLES.pdf`
}
